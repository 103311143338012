<template>
  <base-utils-copy-to-clipboard
    ref="copy"
    :value="value"
  ></base-utils-copy-to-clipboard>
</template>

<script>
import { nextTick, ref } from '@nuxtjs/composition-api'
import { INTERCEPTOR_KEYS, useIntercept } from '~/composables'
import BaseUtilsCopyToClipboard from '~/components/base/utils/BaseUtilsCopyToClipboard.vue'

export default {
  name: 'TheGlobalCopyAction',
  components: { BaseUtilsCopyToClipboard },
  setup(_, { root }) {
    const { intercept } = useIntercept(root)

    const value = ref()
    const copy = ref()

    intercept(
      INTERCEPTOR_KEYS.ACTION_CLICK_EVENT,
      ({ name, params, event }) => {
        if (name === 'cp') {
          value.value =
            params?.trim() || event.target?.textContent?.trim() || undefined

          nextTick(() => {
            copy.value.invokeCopy()
          })
        }
      }
    )

    return {
      value,
      copy,
    }
  },
}
</script>
