<script>
export default {
  name: 'ProductCardExtraBadge',
  functional: true,
  props: {
    text: {
      type: String,
      default: undefined,
    },
    to: {
      type: String,
      default: undefined,
    },
  },
  render(h, context) {
    return h(
      context.props.to ? 'nuxt-link' : 'div',
      {
        ...context.data,
        props: {
          to: context.props.to,
        },
        class: [
          'product-card-extra-badge pa-1 text-decoration-none text-no-wrap',
          ...(Array.isArray(context.data.class)
            ? context.data.class
            : [context.data.class]),
        ],
      },
      context.children
    )
  },
}
</script>

<style lang="scss">
.product-card-extra-badge {
  position: absolute;
  left: 0;
  top: 10px;
  background-color: white;
}
</style>
