import { LineItem } from '~/commons/interfaces/models/checkout/cart/line-item/LineItem'
import { OrderLineItem } from '~/commons/interfaces/models/checkout/order/OrderLineItem'

export function getProductLineItems<T extends LineItem | OrderLineItem>(
  lineItems: T[]
): T[] {
  return lineItems.filter(
    (lineItem) =>
      lineItem.type === 'product' || lineItem.type === 'bs_free_product'
  )
}
