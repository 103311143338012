<template>
  <v-app>
    <base-icon-sprit-container></base-icon-sprit-container>

    <the-headbanger></the-headbanger>
    <the-header-switch
      v-if="$config.featureFlags.isBsMbSwitchEnabled"
    ></the-header-switch>
    <app-bar></app-bar>

    <v-main>
      <v-container class="px-sm-12 px-md-3 pt-0 pt-md-3">
        <lazy-hydrate when-idle>
          <the-breadcrumb-container
            class="mb-2 mb-md-5"
          ></the-breadcrumb-container>
        </lazy-hydrate>
        <nuxt />
      </v-container>
    </v-main>

    <the-notification></the-notification>
    <customer-login-container></customer-login-container>

    <lazy-hydrate when-visible>
      <the-footer></the-footer>
    </lazy-hydrate>

    <cookie-manger-container></cookie-manger-container>
    <client-only>
      <navigator-drawer-container></navigator-drawer-container>
      <cart-side-container></cart-side-container>

      <the-bottom-navigator
        v-if="$vuetify.breakpoint.xsOnly"
        app
        fixed
      ></the-bottom-navigator>

      <newsletter-container></newsletter-container>

      <the-global-copy-action></the-global-copy-action>
    </client-only>
  </v-app>
</template>

<script>
import AppBar from '~/components/app-bar/AppBar'
import TheBreadcrumbContainer from '~/components/TheBreadcrumbContainer'
import TheNotification from '~/components/TheNotification'
import TheFooter from '~/components/TheFooter'
import TheBottomNavigator from '~/components/TheBottomNavigator'
import CartSideContainer from '~/components/cart/side/CartSideContainer'
import NavigatorDrawerContainer from '~/components/navigator-drawer/NavigatorDrawerContainer'
import CustomerLoginContainer from '~/components/customer/login/CustomerLoginContainer'
import CookieMangerContainer from '~/components/cookie/CookieMangerContainer'
import BaseIconSpritContainer from '~/components/base/icon/BaseIconSpritContainer'
import TheHeadbanger from '~/components/TheHeadbanger'
import NewsletterContainer from '~/components/newsletter/NewsletterContainer'
import TheHeaderSwitch from '~/components/TheHeaderSwitch'
import TheGlobalCopyAction from '~/components/TheGlobalCopyAction.vue'

export default {
  components: {
    TheGlobalCopyAction,
    TheHeaderSwitch,
    NewsletterContainer,
    TheHeadbanger,
    BaseIconSpritContainer,
    CookieMangerContainer,
    NavigatorDrawerContainer,
    CustomerLoginContainer,
    CartSideContainer,
    TheBottomNavigator,
    TheFooter,
    TheNotification,
    TheBreadcrumbContainer,
    AppBar,
  },
}
</script>
