<script>
import { mergeData } from 'vue-functional-data-merge'
import BaseIcon from '~/components/base/icon/BaseIcon'
import CmsUtilLink from '~/components/cms/util/CmsUtilLink.vue'

export default {
  name: 'BaseButton',
  functional: true,
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dynamic: {
      type: Boolean,
      default: false,
    },
    noHover: {
      type: Boolean,
      default: false,
    },
    noRipple: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: undefined,
    },
    replace: {
      type: Boolean,
      default: false,
    },
  },
  render(h, context) {
    const props = context.props

    function genContent() {
      return h(
        'span',
        {
          staticClass: 'base-button__content',
        },
        context.children
      )
    }

    function genLoading() {
      return h(
        'span',
        {
          class: 'base-button__loader',
        },
        [
          h(BaseIcon, {
            props: {
              mdi: 'loading',
            },
          }),
        ]
      )
    }

    return h(
      props.to
        ? typeof props.to === 'string'
          ? CmsUtilLink
          : 'nuxt-link'
        : 'button',
      mergeData(context.data, {
        props: {
          to: props.to,
          url: typeof props.to === 'string' ? props.to : undefined,
          replace: props.replace,
        },
        attrs: {
          disabled: props.disabled,
          type: props.to ? undefined : 'button',
        },
        staticClass: 'base-button',
        directives: [
          props.noRipple || props.icon
            ? {}
            : {
                name: 'bs-ripple',
                value: true,
              },
        ],
        class: {
          'base-button--loading': props.loading,
          'base-button--small': props.small,
          'base-button--large': props.large,
          'base-button--x-large': props.xLarge,
          'base-button--block': props.block,
          'base-button--icon': props.icon,
          'base-button--rounded': props.rounded,
          'base-button--outlined': props.outlined,
          'base-button--bold': props.bold,
          'base-button--fab': props.fab,
          'base-button--disabled': props.disabled,
          'base-button--no-hover': props.noHover,
          'base-button--dynamic': props.dynamic,
        },
      }),
      [genContent(), props.loading && genLoading()]
    )
  },
}
</script>
