/* eslint-disable camelcase */
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { getProductCustomField } from '~/helpers'
import { ATTRIBUTE_PRODUCT_SAFETY } from '~/services/cfAttributes'

export function getProductSafety(product: Product): null | {
  company: null | undefined | string
  mail: null | undefined | string
  street: null | undefined | string
  street_number: null | undefined | string
  city: null | undefined | string
  zipcode: null | undefined | string
  country: null | undefined | string
  country_code: null | undefined | string
} {
  const safetyString = getProductCustomField(product, ATTRIBUTE_PRODUCT_SAFETY)

  try {
    if (safetyString) {
      return JSON.parse(safetyString)
    }
  } catch (e) {}

  return null
}
