import { LineItem } from '~/commons/interfaces/models/checkout/cart/line-item/LineItem'
import { OrderLineItem } from '~/commons/interfaces/models/checkout/order/OrderLineItem'

export function isLineItemBsFreeProductTypPseudo<
  T extends LineItem | OrderLineItem
>(lineItem: T): boolean {
  return (
    lineItem.type === 'bs_free_product' &&
    lineItem.payload?.bsFreeProductType === 'pseudo'
  )
}
